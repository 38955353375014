import React from "react";
import { Link } from "gatsby"

const Footer = () => {
  return (
          <footer className="mt-64 w-64 text-xs flex mx-auto text-center opacity-50">
            <div className="flex-grow">© {new Date().getFullYear()} Captioned</div>
            <div className="flex-grow"><Link to="/terms-ios" className="underline">Terms</Link></div>
            <div className="flex-grow"><a href="https://www.iubenda.com/privacy-policy/13230778" className="underline">Privacy Policy</a></div>
          </footer>
          )
};
export default Footer;
